/***************************************************************
 * Helper functions for older browsers
 ***************************************************************/
if (!Object.hasOwnProperty("create")) {
	Object.create = function(parentObj) {
		function tmpObj() {}

		tmpObj.prototype = parentObj;
		return new tmpObj();
	};
}
if (!Object.hasOwnProperty("defineProperties")) {
	Object.defineProperties = function(obj, props) {
		for (var prop in props) {
			Object.defineProperty(obj, prop, props[prop]);
		}
	};
}

if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function(obj, start) {
		for (var i = start || 0, j = this.length; i < j; i++) {
			if (this[i] === obj) {
				return i;
			}
		}
		return -1;
	};
}

if (!String.prototype.toCamelCase) {
	String.prototype.toCamelCase = function() {
		var rs = this;
		var specials = "%!:/;.,*$£¤}=])@^\\`|[({'`#\"~&+°<>²®-¨^_";
		specials = specials.split("");
		var i = 0;
		var lg = specials.length;
		for (i = 0; i < lg; i++) {
			while (rs.indexOf(specials[i]) != -1) {
				rs = rs.replace(specials[i], "");
			}
		}

		rs = rs.replace(/^([A-Z])|\s(\w)/g, function(match, p1, p2, offset) {
			if (p2) return p2.toUpperCase();
			return p1.toLowerCase();
		});
		return rs;
	};
}

(function() {
	var lastTime = 0;
	var vendors = ["webkit", "moz"];
	for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
		window.requestAnimationFrame = window[vendors[x] + "RequestAnimationFrame"];
		window.cancelAnimationFrame =
			window[vendors[x] + "CancelAnimationFrame"] || window[vendors[x] + "CancelRequestAnimationFrame"];
	}
	if (!window.requestAnimationFrame) {
		window.requestAnimationFrame = function(callback, element) {
			var currTime = new Date().getTime();
			var timeToCall = Math.max(0, 16 - (currTime - lastTime));
			var id = window.setTimeout(function() {
				callback(currTime + timeToCall);
			}, timeToCall);
			lastTime = currTime + timeToCall;
			return id;
		};
	}

	if (!window.cancelAnimationFrame) {
		window.cancelAnimationFrame = function(id) {
			clearTimeout(id);
		};
	}
})();

function getScrollLimit() {
	var limit = Math.max(
		document.body.scrollHeight,
		document.body.offsetHeight,
		document.documentElement.clientHeight,
		document.documentElement.scrollHeight,
		document.documentElement.offsetHeight
	);
	return limit;
}

function validateEmail($input) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	var check = re.test($input.val());
	$input.parent().addClass("warning");
	if (check) $input.parent().removeClass("warning");
	return check;
}

function validateZip($input) {
	var re = /^[0-9]{5}$/;
	var check = re.test($input.val());
	$input.parent().addClass("warning");
	if (check) $input.parent().removeClass("warning");
	return check;
}

function validateNotEmpty($input) {
	var val = $input.val();
	val = val.split(" ").join("");
	var check = val.length > 0;

	var isTxtArea = $input.parent().hasClass("trumbowyg-box");
	var $parent = isTxtArea ? $input.parent().parent() : $input.parent();
	if (check) {
		$parent.removeClass("warning");
	} else {
		$parent.addClass("warning");
		console.log($parent);
	}
	return check;
}

function validatePassword($input) {
	var val = $input.val();
	if (window.location.href.indexOf("new") === -1 && val === "") return true;
	var re = /^[0-9-A-Z-a-z]{4,12}$/;
	var check = re.test(val);
	$input.parent().addClass("warning");
	if (check) $input.parent().removeClass("warning");
	return check;
}

function validateLink($input) {
	var val = $input.val();
	$input.removeClass("warning");
	if (val === "") return true;
	var re = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/;
	var check = re.test(val);

	var isHttp = val.indexOf("http://") != -1 || val.indexOf("https://") != -1;
	check = !isHttp ? false : check;
	$input.addClass("warning");
	if (check) $input.removeClass("warning");
	return check;
}

function cancel(e) {
	e.preventDefault();
	e.stopPropagation();
	return false;
}

document.ondragstart = document.ondragover = document.ondragend = document.ondragout = document.ondragleave = document.ondrop = function(
	e
) {
	if (e.target.className && e.target.className.indexOf("draggable") === -1) {
		e.preventDefault();
		return cancel(e);
	}
};

function addUpload(target, callback, button, form) {
	//console.log(target);
	target.ondragstart = target.ondragend = target.ondragleave = target.ondragout = cancel;
	target.ondragover = function(e) {
		//console.log(target);
		if (!$(target).hasClass("ondragover")) $(target).addClass("ondragover");
		return cancel(e);
	};

	target.ondrop = function(e) {
		//console.log(target);
		// now do something with:
		var f = document.getElementById(form);
		var files = e.dataTransfer.files;
		var formData = new FormData(form);
		formData.append("_token", $("#form-upload-img-token").val());
		for (var i = 0; i < files.length; i++) {
			formData.append("image_" + i, files[i]);
		}
		if ($(target).attr("data-width")) formData.append("width", $(target).attr("data-width"));
		if ($(target).attr("data-height")) formData.append("height", $(target).attr("data-height"));
		$(target).removeClass("ondragover");
		// now post a new XHR request
		var xhr = new XMLHttpRequest();
		xhr.open("POST", f.getAttribute("action"));
		xhr.onload = function() {
			if (xhr.status === 200) {
				//console.log('all done: ' + xhr.status);
				callback(target, xhr.response);
			} else {
				//console.log('Something went terribly wrong...');
			}
		};
		xhr.send(formData);

		return cancel(e);
	};
	if (button) {
		var data = {
			_token: $("#form-upload-img-token").val()
		};
		if ($(target).attr("data-realw")) data.width = $(target).attr("data-realw");
		if ($(target).attr("data-realh")) data.height = $(target).attr("data-realh");
		new AjaxUpload(button.id, {
			action: $("form#" + form).attr("action"),
			name: $("form#" + form).attr("data-name"),
			data: data,
			onSubmit: function(file, extension) {},
			onComplete: function(file, rs) {
				callback(target, rs);
			}
		});
	}
}

function getVimeoSrc(id) {
	var src = "//player.vimeo.com/video/" + id + "?portrait=0&color=306eca";
	return src;
}

function getYoutubeSrc(id) {
	var src = "http://www.youtube.com/embed/" + id + "?enablejsapi=1&control=0";
	return src;
}

function getVimeoApiUrl(id) {
	var url = "https://vimeo.com/api/v2/video/" + id + ".json";
	return url;
}

function getYoutubeImgUrl(id) {
	//var url = "https://img.youtube.com/vi/" + id + "/0.jpg";
	var url = "https://i.ytimg.com/vi/" + id + "/maxresdefault.jpg";

	return url;
}

function getYoutubeId(youtubeUrl) {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	var match = youtubeUrl.match(regExp);
	return match && match[7].length == 11 ? match[7] : "";
}

function getVimeoId(vimeoUrl) {
	//https://vimeo.com/193729267
	return vimeoUrl.replace("https://vimeo.com/", "");
}

//console.log(navigator.userAgent);
var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
// For use within normal web clients
var isiPad = navigator.userAgent.match(/iPad/i) != null;

var Events = Events || {};
Events.mouseover = "mouseover";
Events.mouseout = "mouseout";
Events.mouseenter = "mouseenter";
Events.mouseleave = "mouseleave";
Events.mousedown = "mousedown";
Events.mouseup = "mouseup";
Events.click = "click";
Events.change = "change";
Events.focusIn = "focusin";
Events.focusOut = "focusout";
Events.submit = "submit";
Events.paste = "paste";
Events.error = "error";
Events.keyup = "keyup";
