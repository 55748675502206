(function(ns) {
	ns.Main = function() {
		this.controller = null;
		this.w = window.innerWidth;
		this.h = window.innerHeight;
		this.mousex = 0;
		this.mousey = 0;
		this.nextUrl = "";
		this.init = function() {
			webshims.polyfill();

			this.addListeners();
			this.loop();

			this.setController();
			this.setFilters();
		};

		this.addListeners = function() {
			window.onload = this.onload;
			$(window).on("resize", this.resize);
			$(document).mousemove(this.mousemove);
			document.addEventListener("touchmove", this.touchmove, false);

			$(".filters-item")
				.find("input,select")
				.bind(Events.change, this.filterChange);
			$(".form-item-cancel").on(Events.click, this.clickCancelFilter);
		};

		this.setFilters = function() {
			var input = null;
			$(".form-item-cancel").each(function() {
				input = $(this)
					.parent()
					.find(".form-input");
				if (input && input.val() === "") {
					$(this).hide();
				} else if (input) {
					$(this).show();
				}
			});
		};

		this.filterChange = function(e) {
			var filters = [];
			var dbcolumn = "";
			var val = "";
			$(".filters-item")
				.find("input,select")
				.each(function() {
					val = $(this).val();
					if ($(this).hasClass("form-select")) {
						val = $(this)
							.find("option:selected")
							.val();
					}
					if (val !== "") {
						dbcolumn = $(this).attr("data-dbcolumn");
						val = val.replace(/\//g, "");
						val =  encodeURIComponent(val);
						filters.push(dbcolumn + "=" + val); 
					}
				});
			var url = $(".filters").attr("data-url");
			if (filters.length > 0) {
				url = url.replace("no-filter", filters.join("&"));
			}
			window.location = url;
		};

		this.clickCancelFilter = function(e) {
			var input = $(this)
				.parent()
				.find(".form-input");
			if (input) {
				input.val("");
				main.filterChange(null);
			}
		};

		this.loop = function() {
			if (main.controller && main.controller.loop) main.controller.loop();
			if (main.header && main.header.loop) main.header.loop();
			requestAnimationFrame(main.loop);
		};

		this.onload = function() {
			main.isloaded = true;
			if (main.controller && main.controller.onload) main.controller.onload();
		};

		this.mousemove = function(e) {
			main.mousex = e.clientX;
			main.mousey = e.clientY;
		};
		this.touchmove = function(e) {
			main.mousex = e.touches[0].clientX;
			main.mousey = e.touches[0].clientY;
		};

		this.setController = function() {
			if (window.uri) {
				switch (uri) {
					case "/":
						main.controller = new AKARUCMS.HomeController();
						break;
					case "/user":
						main.controller = new AKARUCMS.UserController();
						break;
					default:
						main.controller = new AKARUCMS.BaseController();
						break;
				}
				if (!main.controller) {
					console.log("No controller for uri : " + uri);
					return false;
				}
			}

			main.controller.init();
		};

		this.resize = function(e) {
			main.w = window.innerWidth;
			main.h = window.innerHeight;
			if (main.controller) main.controller.resize(main.w, main.h);
		};

		/**
		 * callGet
		 * @param url
		 * @param callback
		 */
		this.callGet = function(url, callback) {
			request = new XMLHttpRequest();
			request.open("GET", url, true);
			request.onreadystatechange = function() {
				if (this.readyState === 4) {
					if (this.status >= 200 && this.status < 400) {
						// Success!
						if (callback) callback(this.responseText);
					} else {
					}
				}
			};
			request.send();
			request = null;
		};

		this.callPost = function(url, postparams, callback) {
			var data = "";
			if (typeof postparams != "string") {
				data = [];
				for (var s in postparams) {
					data.push(s + "=" + postparams[s]);
				}
				data = data.join("&");
			} else {
				data = postparams;
			}
			var request = new XMLHttpRequest();
			request.onload = function() {
				if (request.status >= 200 && request.status < 400) {
					// Success!
					if (callback) callback(this.responseText);
				} else {
					if (callback) callback(request);
				}
			};

			request.onerror = function() {
				alert(request.message);
			};
			request.open("POST", url, true);
			request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
			request.send(data);
		};

		this.showLoader = function(msg) {
			//$(document.body).css("overflow", "hidden");
			$(".loader-content-txt").html(msg);
			TweenLite.fromTo(".loader", 0.4, { opacity: 0, display: "block" }, { opacity: 1, ease: Quad.easeOut });
		};

		this.hideLoader = function(duration, nextUrl) {
			TweenLite.to(".loader-content-animation", 0.4, {
				delay: 0.2,
				opacity: 0,
				display: "none",
				ease: Quad.easeOut
			});
			TweenLite.fromTo(
				".loader-content-txt",
				0.4,
				{ opacity: 0, display: "block" },
				{
					delay: 0.4,
					opacity: 1,
					ease: Quad.easeOut
				}
			);
			$(".loader").bind(Events.click, main.hideLoaderComplete);
			TweenLite.set(".loader", { delay: duration, onComplete: main.hideLoaderComplete });
			main.nextUrl = nextUrl;
		};

		this.hideLoaderComplete = function() {
			$(".loader").unbind(Events.click, main.hideLoaderComplete);
			TweenLite.killTweensOf(".loader");
			TweenLite.to(".loader", 0.4, {
				opacity: 0,
				display: "none",
				ease: Quad.easeOut,
				onComplete: function() {
					$(".loader-content-txt").html("");
					if (main.nextUrl) window.location.href = main.nextUrl;
					main.nextUrl = "";
					$(document.body).css("overflow", "auto");
				}
			});
		};
	};
})(AKARUCMS);

var main = new AKARUCMS.Main();
